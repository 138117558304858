body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
		'Noto Color Emoji';
	position: fixed;
	top: 0;
	left: 0;
	overflow: auto;
	max-height: 100%;

	color-scheme: dark;
}

main {
	padding: 15px;
	min-height: 100%;
	max-height: 100%;
	height: 100%;
}

/* headings */

h1,
h2,
h3,
h4 {
	font-weight: bold;
}

/* pota */
h1 {
	font-size: 3em;
	margin-bottom: 10px;
	margin-left: -10px;
}

/* colors */

body {
	color: #c9d1d9;
	background: #0d1117;
}
a,
a:link {
	color: #58a6ff;
}
a:visited {
	color: #8e96f0;
}

/* quote */

blockquote {
	color: #a6b8c6;
	border-left: 4px solid;
	border-color: #485f71;
	display: inline-block;

	padding: 10px;
	padding-left: 15px;
	line-height: 1.5;
}

blockquote a {
	color: #bae1ff;
}

/* table */

table {
	margin-bottom: 20px;
	margin-top: 10px;
	width: 100%;
}
thead {
	position: sticky;
	top: 0;
	z-index: 10;
	background: #070a0d;
}
th {
	font-weight: bold;
}
td,
th {
	padding: 6px 13px;
	border: 1px solid #292a52;
	padding-bottom: 9px;
	line-height: 1.4;
}

td * {
	line-height: 1.4;
}

tr td:first-child {
	white-space: nowrap;
}

p,
ul,
ol,
li {
	line-height: 1.6;
}

/* content */

article {
	position: relative;

	max-width: 650px;
	margin-left: 10px;

	font-size: 110%;
}

/* design */
article::before {
	content: 'pota';
	position: absolute;
	left: 110%;
	top: -48px;
	writing-mode: vertical-rl;
	font-size: 42em;
	opacity: 0.2;
	overflow: hidden;
	white-space: nowrap;
	pointer-events: none;
}

/* text */

article a {
	border-bottom: 1px solid inherit;
}

article p {
	padding-bottom: 10px;
	padding-top: 5px;
}

article h2,
article h3,
article h4,
article h5 {
	position: sticky;
	top: -1px;
	z-index: 2;
	background: #0d1117;
	padding-bottom: 10px;
}
article h2 {
	font-size: 1.6em;
}
article h3 {
	font-size: 1.6em;
}
article h4 {
	font-size: 1.4em;
}

article ul {
	padding-left: 15px;
}
article ul a {
	padding: 2px;
	border-color: transparent;
}
article ul a::before {
	content: '⚬ ';
}
article ul a:hover {
	border-color: initial;
}
article ol {
	padding-left: 25px;
	list-style: circle;
}
article footer {
	padding-top: 20px;
	padding-bottom: 10px;
}

article > section {
	padding-top: 25px;
}

article h1 a,
article h2 a,
article h3 a,
article h4 a {
	border: 0;
	color: inherit !important;
}

:global #content {
	height: calc(100% - 5px);
	padding-bottom: 15px;
	padding-right: 10px;
}

.warning {
	color: #e16a6a;
}

:global .warning {
	color: #e16a6a;
}

:global .hidden {
	display: none;
}

input[type='text'],
textarea {
	padding: 4px;
	padding-left: 8px;
	margin-bottom: 4px;
}

* {
	scrollbar-width: thin;
	scrollbar-color: #202835 #0d1117;
}

svg {
	width: 1em;
	height: 1em;
}

template {
	display: none !important;
}

form {
	display: contents;
}

.github {
	width: 1.1em;
	height: 1.1em;
	display: inline-block;
	margin-left: 5px;
}
