.menu {
	margin-left: -15px;
	margin-top: -15px;
	margin-bottom: -15px;
	padding: 15px;
	--menu-background: #04070bad;
	background: var(--menu-background);
	box-shadow: 5px 0 10px #04070bad;
	max-width: 225px;
	padding-right: 15px;
}

.menu a:has(h2) {
	display: block;
	position: sticky;
	top: 0;
	width: 100%;
	padding-bottom: 10px;
	background: var(--menu-background);
}
.menu ul,
.menu ol {
	line-height: 1;
}
.menu h2 {
	font-size: 1.6em;
}
.menu h3 {
	font-size: 1.2em;
}
.menu h4 {
	font-size: 1.15em;
}
.menu li {
	display: block;
}
.menu li a {
	display: block;
	width: 100%;

	padding: 5px;
	padding-top: 6px;
	padding-left: 10px;
	padding-bottom: 8px;
	border-left: 1px solid rgba(186, 225, 255, 0.2);
}
.menu li a:hover {
	background: rgb(140 154 165 / 15%);
	border-left: 1px solid rgb(51 144 215 / 41%);
}
.menu h2 {
	position: sticky;
	top: 0;
}
.menu h3,
.menu h4 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.menu nav ul {
	padding-top: 5px;
	padding-left: 6px;
}
.menu nav ul li {
	padding-left: 1px;
	padding-bottom: 0px;
}

.menu nav ul + h3,
.menu nav ul + h4 {
	padding-top: 5px;
	padding-bottom: 0px;
}

.menu footer {
	padding-top: 10px;
}

:global .menu li.selected {
	background: rgba(140, 154, 165, 0.15);
	border-left: 1px solid rgba(51, 144, 215, 0.41);
}

:global .menu li.selected a {
	color: #a0c6ff;
	border-left: 1px solid white;
}

.montevideo {
	position: fixed;
	right: 33px;
	top: 13px;
	font-size: 260%;
	padding: 5px;
	cursor: pointer;
	z-index: 1;
	background-color: rgba(13 17 23 /0.4);
	border-radius: 4px;
}
