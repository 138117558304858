.toolbar {
	padding: 10px;
}

.toolbar input[type='checkbox'] {
	width: 1.2em;
	height: 1.2em;

	margin: 0;
}
.toolbar a {
	margin-right: 4px;
}

.toolbar * {
	vertical-align: middle;
}
.toolbar span {
	margin-right: 5px;
	margin-left: 5px;
}

main[data-pathname^='/playground'] figure {
	border: 0;
}

main[data-pathname^='/playground'] article {
	max-width: 100%;
	overflow: hidden;
	min-height: 0;
}

main[data-pathname^='/playground'] article,
main[data-pathname^='/playground'] article section {
	max-width: none !important;
	min-height: 0;
	background: transparent;
}
:global main[data-pathname^='/playground'] #content {
	overflow: hidden;
	height: 100% !important;
}

:global main[data-pathname^='/playground'] #left {
	width: 60%;
	max-width: 60% !important;
}
:global main[data-pathname^='/playground'] #right {
	width: 40%;
	max-width: 40% !important;
	padding-left: 10px;
	overflow: auto;
}

:global main[data-pathname^='/playground'] figure {
	will-change: scroll-position;
	transform: translatez(0);
	overflow-y: auto;
	min-height: 0;
}

main[data-pathname^='/playground'] article:before {
	content: 'pota';
	font-size: 21em;
	left: 21px;
	opacity: 0.04;
	overflow: hidden;
	position: absolute;
	top: 34px;
	white-space: nowrap;
	writing-mode: vertical-rl;
	pointer-events: none;
}

:global main[data-pathname^='/playground'] .no-playground {
	display: none;
}

:global main[data-pathname^='/playground'] article aside {
	font-size: 18px;
}

pota-collapse {
	width: 100%;
	height: 100%;
}

.themeSelector {
	margin-left: 5px;
	text-transform: capitalize;
}
