/* container */

.container {
	position: relative;
	padding-bottom: 1px;
	padding-right: 0px;
}
.container figure {
	border: 1px solid rgba(5, 134, 234, 0.2);
	margin-bottom: 10px;
	position: relative;
	width: 100%;
	margin-top: 1px;
}

.frame {
	background: #0d1117;
	padding: 15px;
}

.frameContainer {
	padding: 15px;
}
iframe {
	height: 27.31px;
	min-height: 27.31px;
	width: calc(100% - 1px);
	max-height: 600px;
}

figcaption {
	padding: 15px;
	background: #171f26;
	border-left: 0px;
	border-right: 0px;
}
figcaption:empty {
	display: none;
}
figcaption mark {
	background: #0d1117c9;
}

/* links */

.container aside {
	font-size: 0.9em;
	max-width: 100%;
	opacity: 0.7;
	padding: 0;
	text-align: right;
	margin-top: 5px;
	margin-bottom: 10px;
}
.container aside a {
	border: 0;
}

/* marks */
mark {
	background: rgb(32 41 54);
	white-space: pre-wrap;
}

mark::after {
	content: '`';
}
mark::before {
	content: '`';
}

.snippetContainer {
	position: relative;
	display: grid;
}

.snippetContainer[editable]::after {
	color: #ffffff61;
	content: 'Editable';
	display: inline-block;
	font-size: small;
	pointer-events: none;
	position: absolute;
	right: 20px;
	bottom: 20px;
	z-index: 2;
}
