tm-textarea {
	min-width: 100%;
	min-height: 100%;

	padding: 20px;

	font-size: 18px;
	line-height: 1.25;

	tab-size: 1 !important;
}

/* change background */

tm-textarea::part(root) {
	background: #11161d;
	color: hsl(215, 22%, 55%);
}

/* selection color */

tm-textarea::part(textarea)::selection {
	background: #2a374a;
}

/* special for shell snippets  */

tm-textarea.shell {
	border: 1px solid rgba(5, 134, 234, 0.2);
}

/* special for js snippets  */

tm-textarea.snippet {
	max-height: 810px;
	min-height: 64px;
	resize: both;
}

/* special for playground  */

tm-textarea.playground {
	padding: 10px;
	min-width: 100%;
	min-height: 100%;
	max-width: 100%;
	max-height: 100%;
	overflow: scroll;
}
tm-textarea.playground::part(root) {
	background: #11161d42;
}

/* add line-numbers  */

tm-textarea.line-numbers::part(line)::before {
	display: inline-block;
	text-align: right;
	counter-reset: line-count calc(var(--tm-line-number) + 1);
	min-width: 3ch;
	padding-right: 2ch;
	content: counter(line-count);
	color: #7777ce5e;
}
tm-textarea.line-numbers::part(textarea) {
	margin-left: 5ch;
}

@media all and (width < 750px) {
	tm-textarea {
		padding: 10px;
	}
	tm-textarea.line-numbers::part(line)::before {
		display: none;
	}
	tm-textarea.line-numbers::part(textarea) {
		margin-left: 0;
	}
}
/*
tm-textarea.line-numbers::part(line)::before {
	color: #7777ce5e;
}
tm-textarea.line-numbers::part(root) {
	--offset: calc(var(--tm-line-digits) * 1ch + 1ch);
}
tm-textarea.line-numbers::part(line)::before {
	position: absolute;
	transform: translateX(calc(var(--offset) * -1));
	counter-reset: line-number calc(var(--tm-line-number) + 1);
	content: counter(line-number);
}

tm-textarea.line-numbers::part(line),
tm-textarea.line-numbers::part(textarea) {
	transform: translateX(var(--offset));
}
*/

/* doesnt work with custom elements x.X  */

tm-textarea::-webkit-resizer {
	background: rgb(0 0 0 / 15%);
	border-top-left-radius: 15px;
	border: 1px solid rgb(0 0 0 / 30%);
}

tm-textarea::resizer {
	background: rgb(0 0 0 / 15%);
	border-top-left-radius: 15px;
	border: 1px solid rgb(0 0 0 / 30%);
}
